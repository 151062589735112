<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <v-card :disabled="loading" :loading="loading">
      <v-card-title>
        {{ $t("leave.approvals.leave_approval") }}
        <v-spacer />
        <v-btn depressed icon @click="onClickCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3" v-if="leave">
        <v-flex xs12 mt-2>
          <span
            v-html="
              $t('leave.approvals.message', [
                `${leave.leaveRequest.employee.firstName}
          ${leave.leaveRequest.employee.lastName}`,
                $helpers.formattedDate(leave.leaveRequest.leaveRequestDays[0].date),
                $helpers.formattedDate(
                  leave.leaveRequest.leaveRequestDays[leave.leaveRequest.leaveRequestDays.length - 1].date
                ),
                leave.leaveRequest.leaveRequestDays.length,
                $store.state.auth.language === 'tr'
                  ? leave.leaveRequest.leaveType.name
                  : leave.leaveRequest.leaveType.englishName
              ])
            "
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field v-model.trim="leaveApprovalDTO.note" name="note" :label="$t('global.note')" />
        </v-flex>
        <v-flex xs12>
          <v-radio-group row mandatory v-model="leaveApprovalDTO.approved">
            <v-radio color="primary" :value="false" :label="$t('leave.request.denied')" />
            <v-radio color="primary" :value="true" :label="$t('leave.request.approved')" />
          </v-radio-group>
        </v-flex>
      </v-card-text>
      <v-card-actions class="dialog-footer">
        <v-spacer></v-spacer>
        <v-btn depressed @click.stop="onClickSave">{{ $t("buttons.save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "confirm-dialog",
    props: {
      value: Boolean,
      data: Object
    },
    data: () => ({
      dialog: false,
      loading: false,
      leave: null,
      leaveApprovalDTO: {
        approved: false,
        note: null
      }
    }),
    watch: {
      value: {
        handler(status) {
          this.dialog = status;
        },
        immediate: true
      },
      data: {
        handler(leaveRequest) {
          this.leave = leaveRequest;
        },
        deep: true
      }
    },
    methods: {
      onClickCancel() {
        this.leaveApprovalDTO.approved = null;
        this.leaveApprovalDTO.note = null;
        this.leave = null;
        this.$emit("close");
      },
      onClickSave() {
        this.loading = true;
        this.$api.leaveRequestService
          .approve(this.leave.id, this.leaveApprovalDTO)
          .then(data => {
            if (data) {
              this.$emit("success");
              this.leaveApprovalDTO.note = null;
              this.leaveApprovalDTO.note = null;
              this.leave = null;
            }
          })
          .catch(e => {
            console.log("e", e);
          })
          .then(() => {
            this.loading = false;
          });
      }
    }
  };
</script>

<style scoped></style>
